import { Employee } from "../employee-list";
import React from "react";
import imgTorbenSchwarzer from ".././media/torbenSchwarzer.png";
import i18n from "../../../generic/i18n";

export const torbenSchwarzer: Employee = {
  slug: "torben-schwarzer",
  displayName: "Torben Schwarzer",
  displayNameSuffix: "MLE",
  title: "lawyer-male",
  isExternal: true,
  subTitle: "",
  awards: [],
  img: imgTorbenSchwarzer,
  languages: ["deu", "eng", "esp", "fra"],
};
