import { VStack, Button, Box } from "@chakra-ui/react";

import { t } from "i18next";
import { Trans } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { BannerWithHeading } from "../../generic/layout/bannerWithHeading";
import { HeadingMedium } from "../../generic/layout/headingMedium";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { TextHeading } from "../team/EmployeeDetails";

export function LawyerDisputeResolution() {
  const { languageCode } = useParams();
  return (
    <VStack spacing={0}>
      <BannerWithHeading bgUrl="/media/bg3.jpg" headingText={t("career")} />

      <Box py={8}>
        <MaxWidthContainer>
          <HeadingMedium mb={0}>
            Rechtsanw&auml;lte (m/w/d) f&uuml;r den Bereich Dispute Resolution
          </HeadingMedium>
          <p>
            <strong>
              <span></span>
            </strong>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              Zur Verst&auml;rkung unseres Teams in Hamburg suchen wir ab sofort
              in Vollzeit Rechtsanw&auml;lte (m/w/d) f&uuml;r den Bereich
              Dispute Resolution.
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              Unsere Rechtsanw&auml;lt:innen vertreten unsere Mandanten in
              nationalen und internationalen Schiedsverfahren nach allen
              f&uuml;hrenden institutionellen Schiedsregeln sowie in
              Zivilprozessen vor staatlichen Gerichten, einschlie&szlig;lich der
              Ma&szlig;nahmen des einstweiligen Rechtsschutzes und der
              Zwangsvollstreckung.
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              Wir bieten Ihnen eine spannende T&auml;tigkeit in einer
              aufstrebenden Kanzlei. Neben der markt- und leistungsgerechten
              Verg&uuml;tung bieten wir Ihnen die M&ouml;glichkeit des
              gemeinsamen Wachsens in einem jungen dynamischen Team. Unsere
              Struktur erlaubt es uns, Sie ab dem ersten Tag unter
              Ber&uuml;cksichtigung Ihrer F&auml;higkeiten und Interessen in die
              Mandatsarbeit voll einzubinden. Wir sind &uuml;berzeugt, dass
              Vielfalt Teams st&auml;rker, intelligenter und effektiver macht
              und dass hervorragende Leistung nur im Team erbracht werden
              kann.&nbsp;
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              Wir sind stolz auf unsere offene, angenehme und respektvolle
              Unternehmenskultur und freuen uns &uuml;ber Ihre Bewerbung.
            </span>
          </p>
          <p>&nbsp;</p>

          <TextHeading mt={4} text={"Aufgabenbereiche"} />
          <ul>
            <li>
              <span>
                Parteivertretung in nationalen und internationalen
                Schiedsverfahren (ICC, DIS, etc.)
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span>
                Unterst&uuml;tzung bei Schiedsrichter-Mandaten als
                Sekret&auml;r:in des Schiedsgerichts
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span>Prozessvertretung vor staatlichen Gerichten</span>
            </li>
          </ul>
          <p>&nbsp;</p>

          <TextHeading mt={4} text={"Qualifikationen"} />
          <p>
            <span>Sie verf&uuml;gen &uuml;ber:&nbsp;</span>
          </p>
          <ul>
            <li>
              <span>
                zwei &uuml;berdurchschnittliche juristische Staatsexamina;
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span>sehr gute Englischkenntnisse;</span>
            </li>
          </ul>
          <ul>
            <li>
              <span>ein ausgepr&auml;gtes wirtschaftliches Interesse;</span>
            </li>
          </ul>
          <ul>
            <li>
              <span>eine schnelle Auffassungsgabe und</span>
            </li>
          </ul>
          <ul>
            <li>
              <span>gro&szlig;es Verantwortungsbewusstsein.</span>
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>
            <span>
              Sie zeichnen sich durch hohe Teamf&auml;higkeit,
              Einsatzbereitschaft und eine strukturierte Arbeitsweise aus. Sie
              haben Lust sich selbstst&auml;ndig in verschiedene dynamische
              Aufgabenbereiche einzuarbeiten und kreativ an diversen Projekten
              mitzuwirken. Dann freuen wir uns darauf, Sie kennenzulernen!&nbsp;
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>Richten Sie bitte Ihre </span>
            <strong>
              <span>Bewerbungen per E-Mail an </span>
            </strong>
            <span>Frau Rechtsanw&auml;ltin Dr. Wajma Mangal (</span>
            <a href="mailto:info@mangal.legal">
              <span>
                <span>info@mangal.legal</span>
              </span>
            </a>
            <span>).</span>
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>
              <span>Sie haben noch Fragen?</span>
            </strong>
          </p>
          <p>
            <span>
              Dann rufen Sie uns gern unter der Telefonnummer +49 40 357 00 97 0
              an.
            </span>
          </p>
        </MaxWidthContainer>
      </Box>
    </VStack>
  );
}
