import { Employee } from "../employee-list";
import React from "react";
import imgDrWajmaMangal from ".././media/dr-wajma-mangal.png";
import i18n from "./../../../generic/i18n";
import bestLawyers from ".././media/HB_Dtld_Beste_Anwaelte2024_Dr_Wajma_Mangal.png";

export const drWajmaMangal: Employee = {
  slug: "dr-wajma-mangal",
  displayName: "Dr. Wajma Mangal",
  title: "lawyer-partner-female",
  isExternal: false,
  subTitle: "",
  awards: [bestLawyers],
  img: imgDrWajmaMangal,
  languages: ["deu", "eng", "prs", "pst"],
  linkedInLink: "https://www.linkedin.com/in/wajma-mangal-3b1b1b1b/",
};
