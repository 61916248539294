import { HStack, StackDirection, VStack } from "@chakra-ui/layout";
import {
  Card,
  CardBody,
  Stack,
  Text,
  Image,
  useBreakpointValue,
  IconButton,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { RxDoubleArrowRight } from "react-icons/rx";
import { companyColors } from "../../global/companyColors";
import { Employee } from "./employee-list";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LinkButton } from "../../generic/LinkButton";
import { RiProfileLine } from "react-icons/ri";

export function EmployeeCard({ employee }: { employee: Employee }) {
  const { t } = useTranslation();
  const spacing = useBreakpointValue(
    { base: undefined, md: 4 },
    { ssr: false }
  );
  const { languageCode } = useParams();
  const direction = useBreakpointValue<StackDirection>({
    base: "column",
    md: "row",
  });
  return (
    <SimpleGrid
      /* templateColumns={{ base: "1fr", xl: "0.4fr 0.6fr" }} */
      templateColumns={{ base: "1fr" }}
      w="220px"
      flexDirection="column"
      spacing={4}
      justifyContent={"flex-start"}
      flexGrow={1}
    >
      {employee.hasProfile != false ? (
        <HStack spacing={2}>
          <Link to={`/${languageCode}/team/${employee.slug}`}>
            <Image src={employee.img} rounded={4} />
          </Link>
          {/*  <EmployeeAward employee={employee} /> */}
        </HStack>
      ) : (
        <HStack spacing={2}>
          <Image src={employee.img} rounded={4} />
          {/*  <EmployeeAward employee={employee} /> */}
        </HStack>
      )}
      <VStack
        flexWrap="wrap"
        alignItems="flex-start"
        justifyContent={"space-between"}
      >
        <VStack alignItems={"flex-start"} spacing={0}>
          <Text fontSize="16px">
            {employee.displayName}
            {employee.displayNameSuffix && ", " + employee.displayNameSuffix}
          </Text>

          <Text fontSize={"12px"} color={companyColors.blue[800]}>
            {t(employee.title)}
            {employee.isExternal && <span>, {t("of-counsel")}</span>}
            {/* {employee.subTitle && <span>, {t(employee.subTitle)}</span>} */}
          </Text>
        </VStack>
        {employee.hasProfile != false && (
          <LinkButton
            label={t("to-profile").toString()}
            link={`/${languageCode}/team/${employee.slug}`}
          />
        )}
        {/*  <IconButton
              aria-label="Search database"
              icon={<RxDoubleArrowRight />}
            /> */}
      </VStack>
      {/*       <Box>
        
      </Box> */}
    </SimpleGrid>
  );
}

function EmployeeAward({ employee }: { employee: Employee }) {
  return (
    <>
      {employee.awards && employee.awards.length > 0 && (
        <HStack>
          {employee.awards.map((award) => (
            <Image h={184} src={award} w={100} rounded={4} />
          ))}
        </HStack>
      )}
    </>
  );
}
