import { Employee } from "../employee-list";
import React from "react";
import imgLaraSophieGessner from ".././media/laraGessner.png";
import i18n from "../../../generic/i18n";

export const laraSophieGessner: Employee = {
  slug: "lara-sophie-gessner",
  displayName: "Lara Sophie Geßner",
  title: "student-assistant-female",
  hasProfile: false,
  isExternal: false,
  subTitle: "",
  awards: [],
  img: imgLaraSophieGessner,
  languages: ["deu", "eng"],
};
