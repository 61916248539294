import React, { useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";

import { Container } from "./container";
import { Footer } from "./footer";
import { Header } from "./header";

import i18next from "i18next";
import { useParams } from "react-router-dom";
import { companyColors } from "../../global/companyColors";
import { addDomEvent } from "@chakra-ui/utils";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

export function Layout({ children }: { children: JSX.Element }) {
  const { languageCode } = useParams();

  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    addDomEvent(window, "resize", () => setHeight(window.innerHeight));
  }, []);

  useEffect(() => {
    // Update the document title using the browser API
    i18next.changeLanguage(languageCode, (err, t) => {
      /*     if (err) return console.log("something went wrong loading language", err);
      t("key"); // -> same as i18next.t */
    });
  }, [languageCode]);

  return (
    <Flex
      direction="column"
      minHeight={height}
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "space-between",
      }}
    >
      <Helmet>
        <title>
          MANGAL LEGAL -{" "}
          {languageCode == "en"
            ? "Boutique law firm focused on commercial disputes"
            : "Boutique-Kanzlei für internationale Wirtschaftsstreitigkeiten"}
        </title>
        <meta
          name="description"
          content={
            languageCode == "en"
              ? "Boutique law firm focused on commercial disputes"
              : "Boutique-Kanzlei für internationale Wirtschaftsstreitigkeiten"
          }
        />
      </Helmet>
      <Box>
        <Header />

        <Container as="main" p={0}>
          {children}
        </Container>
      </Box>
      <Box bg={companyColors.blue[200]}>
        <Footer />
      </Box>
    </Flex>
  );
}
