import { AspectRatio, Box, useBreakpointValue } from "@chakra-ui/react";
import { motion, Variants } from "framer-motion";
import { DefaultTFuncReturn } from "i18next";

import { companyColors } from "../../global/companyColors";
import { maxWidth } from "../chakraUiTheme";
import { Heading } from "./heading";
import { MaxWidthContainer } from "./maxWidthContainer";

/* import "./styles.css"; */

export function BannerWithHeading({
  bgUrl,
  headingText,
}: {
  bgUrl: string;
  headingText?: string | undefined | DefaultTFuncReturn;
}) {
  const ratio =
    useBreakpointValue({ base: 550, md: 250 }, { ssr: false }) || 250;

  return (
    <Box
      backgroundImage={`url("${bgUrl}")`}
      backgroundSize="cover"
      w={"100%"}
      maxWidth={maxWidth}
      borderRadius={2}
    >
      <AspectRatio maxW="100%" ratio={1300 / ratio}>
        <Box
          overflow="hidden"
          /*    justifyContent="space-between" */
          display="flex"
          flexDirection="column"
        >
          <MaxWidthContainer>
            {headingText && (
              <motion.div
                initial={{ opacity: 0, y: +100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 2,
                }}
              >
                <Heading color={companyColors.blue[1000]} mb={0}>
                  {headingText}
                </Heading>
              </motion.div>
            )}
          </MaxWidthContainer>
        </Box>
      </AspectRatio>
    </Box>
  );
}
