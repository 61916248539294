import { Employee } from "../employee-list";
import React from "react";
import imgSusanneLeist from ".././media/susanneLeist.png";
import i18n from "../../../generic/i18n";

export const susanneLeist: Employee = {
  slug: "susanne-leist",
  displayName: "Susanne Leist",
  displayNameSuffix: "M.Phil. (Oxford)",
  title: "lawyer-female",
  isExternal: false,
  subTitle: "",
  awards: [],
  img: imgSusanneLeist,
  languages: ["deu", "eng", "esp", "fra"],
};
