import { Employee } from "../employee-list";
import React from "react";
import imgSohrabHashemi from ".././media/sohrabHashemi.png";
import i18n from "../../../generic/i18n";

export const sohrabHashemi: Employee = {
  slug: "sorab hashemi",
  displayName: "Sohrab Hashemi",
  title: "student-assistant-male",
  hasProfile: false,
  isExternal: false,
  subTitle: "",
  awards: [],
  img: imgSohrabHashemi,
  languages: ["deu", "eng", "pst", "prs"],
};
