import { Box } from "@chakra-ui/layout";
import { HStack, Text, useStyleConfig } from "@chakra-ui/react";
import { RxDoubleArrowRight } from "react-icons/rx";
import { Link } from "react-router-dom";
import { companyColors } from "../global/companyColors";

export function LinkButton({
  link,
  label,
  href,
  target,
}: {
  link?: string | undefined;
  label: string;
  href?: string | undefined;
  target?: "_blank" | undefined;
}) {
  const stylesInactive = useStyleConfig("Box", { variant: "linkButton" });

  return (
    <Box>
      {link ? (
        <Box as={Link} to={link} __css={stylesInactive}>
          <HStack>
            <Text fontSize={"16px"} color={companyColors.blue[1000]}>
              {label}
            </Text>
            <RxDoubleArrowRight size={16} />
          </HStack>
        </Box>
      ) : (
        <Box as="a" href={href} target={target} __css={stylesInactive}>
          <HStack>
            <Text fontSize={"16px"} color={companyColors.blue[1000]}>
              {label}
            </Text>
            <RxDoubleArrowRight size={16} />
          </HStack>
        </Box>
      )}
    </Box>
  );
}
