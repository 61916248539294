import {
  Box,
  Divider,
  Grid,
  GridItem,
  SimpleGrid,
  VStack,
} from "@chakra-ui/layout";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BannerWithHeading } from "../../generic/layout/bannerWithHeading";
import { Heading } from "../../generic/layout/heading";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { companyColors } from "../../global/companyColors";
import { employeeList } from "./employee-list";
import { EmployeeCard } from "./EmployeeCard";
import { HStack, useBreakpointValue } from "@chakra-ui/react";
import useMeasure from "react-use-measure";

export function EmployeeList() {
  const { t } = useTranslation();
  const { languageCode } = useParams();
  let [ref, { width, height }] = useMeasure();
  const breakPoint = useBreakpointValue({
    base: "base",
    xs: "xs",
    s: "s",
    md: "md",
    lg: "lg",
    xl: "xl",
  });
  const columnConfig = useBreakpointValue({
    base: "1col",
    lg: "3col",
  });
  return (
    <VStack spacing={0} ref={ref}>
      <BannerWithHeading bgUrl="/media/bg2.jpg" headingText={t("team")} />

      <MaxWidthContainer py={8} w="100%">
        {columnConfig === "1col" ? (
          <VStack spacing={6}>
            {employeeList.map((employee, i) => (
              <>
                <EmployeeCard employee={employee} />
                {i < employeeList.length - 1 && (
                  <Box w={"100%"}>
                    <Divider borderColor={companyColors.blue[400]} />
                  </Box>
                )}
              </>
            ))}
          </VStack>
        ) : (
          <VStack spacing={8} justifyItems={"center"}>
            <EmployeeLine employees={[0, 1, 2]} />
            <Divider borderColor={companyColors.blue[400]} />
            <EmployeeLine employees={[3, 4, 5]} />
            <Divider borderColor={companyColors.blue[400]} />
            <EmployeeLine employees={[6, 7, 8]} />
            <Divider borderColor={companyColors.blue[400]} />
            <EmployeeLine employees={[9]} />
          </VStack>
        )}
      </MaxWidthContainer>
    </VStack>
  );
}
function EmployeeLine({ employees }: { employees: number[] }) {
  return (
    <Grid
      gridTemplateColumns={"0.33fr 10px 0.33fr 10px 0.33fr"}
      gap={10}
      w="100%"
      justifyItems={"center"}
    >
      <GridItem>
        <EmployeeCard employee={employeeList[employees[0]]} />
      </GridItem>

      {employees[1] && (
        <>
          <GridItem>
            <Divider
              borderColor={companyColors.blue[400]}
              orientation="vertical"
              display={{ base: "none", lg: "block" }}
              ml={4}
            />
          </GridItem>
          <GridItem>
            <EmployeeCard employee={employeeList[employees[1]]} />
          </GridItem>
        </>
      )}
      {employees[1] && employees[2] && (
        <>
          <GridItem>
            <Divider
              borderColor={companyColors.blue[400]}
              orientation="vertical"
              display={{ base: "none", lg: "block" }}
              ml={4}
            />
          </GridItem>
          <GridItem>
            <EmployeeCard employee={employeeList[employees[2]]} />
          </GridItem>
        </>
      )}
    </Grid>
  );
}
