import { Badge, Box, Divider } from "@chakra-ui/layout";
import {
  SimpleGrid,
  VStack,
  Text,
  Image,
  HStack,
  useBreakpointValue,
  Button,
  IconButton,
  border,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  fallbackLng,
  TranslatedInfo,
  websiteLanguages,
} from "../../generic/i18n";
import { BannerWithHeading } from "../../generic/layout/bannerWithHeading";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { companyColors } from "../../global/companyColors";
import {
  Employee,
  employeeList,
  EmployeeTranslatedInfo,
  Publication,
} from "./employee-list";
import i18n from "../../generic/i18n";
import { HeadingMedium } from "../../generic/layout/headingMedium";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { RxDoubleArrowRight } from "react-icons/rx";

export function EmployeeDetails() {
  const { employeeSlug, languageCode } = useParams();
  const [employee, setEmployee] = useState<Employee | undefined>(undefined);
  const [employeeTranslatedInfo, setEmployeeTranslatedInfo] = useState<
    (EmployeeTranslatedInfo & TranslatedInfo) | undefined
  >(undefined);
  const breakPoint = useBreakpointValue({
    base: "base",
    xs: "xs",
    s: "s",
    md: "md",
    lg: "lg",
    xl: "xl",
  });

  useEffect(() => {
    if (employeeSlug) {
      let employee = GetEmployeeDetails(employeeSlug);
      setEmployee(employee);
      let employeeTranslatedInfo: EmployeeTranslatedInfo & TranslatedInfo =
        i18n.t(employeeSlug, {
          returnObjects: true,
          lng: languageCode,
          ns: "contentTeam",
        });
      setEmployeeTranslatedInfo(employeeTranslatedInfo);
    }
  }, [employee, languageCode]);

  return (
    <VStack spacing={0} mb={8}>
      <BannerWithHeading bgUrl="/media/bg2.jpg" headingText={t("team")} />
      {/*       <Box>br: {breakPoint}</Box> */}
      <MaxWidthContainer>
        {employee && (
          <Box mt={8}>
            <SimpleGrid
              templateColumns={{ base: "1fr", md: "220px 1fr" }}
              flexDirection="column"
              spacing={8}
              justifyContent="space-between"
              mb={4}
            >
              <Image
                src={employee.img}
                w={{ base: "220px", md: "220px" }}
                /*   maxW={"390px"} */
                h={{ base: "220px", md: "220px" }}
                objectFit={"cover"}
                objectPosition={"top"}
                rounded={4}
              />
              <VStack flexWrap="wrap" alignItems="flex-start">
                <HeadingMedium mb={2}>
                  {employee.displayName}
                  {employee.displayNameSuffix &&
                    ", " + employee.displayNameSuffix}
                </HeadingMedium>
                <Text>
                  {t(employee.title)}
                  {employee.isExternal && <span>, {t("of-counsel")}</span>}
                  {employee.subTitle && <span>, {t(employee.subTitle)}</span>}
                </Text>
                {employee.secondTitle && <Text>{employee.secondTitle}</Text>}

                {employeeTranslatedInfo?.certifications && (
                  <>
                    {employeeTranslatedInfo.certifications.map(
                      (certification, i) => (
                        <Box>{t(certification)}</Box>
                      )
                    )}
                  </>
                )}
              </VStack>
            </SimpleGrid>
            <Text
              mt={6}
              key={languageCode}
              dangerouslySetInnerHTML={{
                __html:
                  t(employee.slug + ".intro", "", {
                    ns: "contentTeam",
                  }) || "",
              }}
            ></Text>

            <TextHeading text={"languages"} />
            <Text>
              {employee.displayName} {t("speaks")}{" "}
              {employee.languages.map((lang, i) => (
                <span key={i}>
                  {i + 1 < employee.languages.length ? "" : t("and") + " "}
                  {t("language.iso639." + lang)}
                  {i + 2 < employee.languages.length ? ", " : ""}
                  {i + 1 == employee.languages.length ? "." : " "}
                </span>
              ))}
            </Text>

            {employeeTranslatedInfo?.positions &&
              employeeTranslatedInfo?.positions.length > 0 && (
                <React.Fragment>
                  <TextHeading text={"positions"} />
                  <Text>
                    {employeeTranslatedInfo?.positions?.length == 1 ? (
                      <>{employeeTranslatedInfo?.positions[0]} </>
                    ) : (
                      <ul>
                        {employeeTranslatedInfo?.positions?.map((position) => (
                          <li>{position}</li>
                        ))}
                      </ul>
                    )}
                  </Text>
                </React.Fragment>
              )}

            {employeeTranslatedInfo?.additionalQualifications &&
              employeeTranslatedInfo?.additionalQualifications.length > 0 && (
                <React.Fragment>
                  <TextHeading text={"additionalQualifications"} />
                  <Text>
                    {employeeTranslatedInfo?.additionalQualifications.length ==
                    1 ? (
                      <>
                        {employeeTranslatedInfo?.additionalQualifications[0]}{" "}
                      </>
                    ) : (
                      <ul>
                        {employeeTranslatedInfo?.additionalQualifications?.map(
                          (additionalQualification) => (
                            <li>{additionalQualification}</li>
                          )
                        )}
                      </ul>
                    )}
                  </Text>
                </React.Fragment>
              )}

            {((employeeTranslatedInfo?.awards &&
              employeeTranslatedInfo?.awards.length > 0) ||
              (employee.awards && employee.awards.length > 0)) && (
              <>
                <TextHeading text={"awards"} />
                <SimpleGrid
                  templateColumns={{
                    base: "1fr",
                    md:
                      employee.awards && employee.awards.length > 0
                        ? "0.1fr 1fr"
                        : "1fr",
                  }}
                  flexDirection="column"
                  spacing={4}
                  justifyContent="space-between"
                  mb={4}
                >
                  {employee.awards && employee.awards.length > 0 && (
                    <HStack>
                      {employee.awards.map((award) => (
                        <Image minW={120} src={award} w={100} rounded={4} />
                      ))}
                    </HStack>
                  )}
                  {employeeTranslatedInfo?.awards &&
                    employeeTranslatedInfo?.awards.length > 0 && (
                      <Box>
                        <Text>
                          {employeeTranslatedInfo?.awards.length > 1 ? (
                            <ul>
                              {employeeTranslatedInfo?.awards?.map(
                                (award, i) => (
                                  <li
                                    key={i}
                                    dangerouslySetInnerHTML={{
                                      __html: award,
                                    }}
                                  ></li>
                                )
                              )}
                            </ul>
                          ) : (
                            <span>{employeeTranslatedInfo?.awards[0]}</span>
                          )}
                        </Text>
                      </Box>
                    )}
                </SimpleGrid>
              </>
            )}

            {employeeTranslatedInfo?.memberships &&
              employeeTranslatedInfo?.memberships.length > 0 && (
                <React.Fragment>
                  <TextHeading text={"memberships"} />
                  <Text>
                    {employeeTranslatedInfo?.memberships?.length == 1 ? (
                      <>{employeeTranslatedInfo?.memberships[0]} </>
                    ) : (
                      <ul>
                        {employeeTranslatedInfo?.memberships?.map(
                          (membership) => (
                            <li>{membership}</li>
                          )
                        )}
                      </ul>
                    )}
                  </Text>
                </React.Fragment>
              )}

            {employeeTranslatedInfo?.publications &&
              employeeTranslatedInfo?.publications.length > 0 && (
                <React.Fragment>
                  <TextHeading text={"publications"} />

                  <VStack alignItems={"flex-start"}>
                    {employeeTranslatedInfo?.publications
                      //sort by year and month
                      .sort(
                        (a, b) =>
                          new Date(b.date).getTime() -
                          new Date(a.date).getTime()
                      )
                      .map((publication) => (
                        <PublicationCard publication={publication} />
                      ))}
                  </VStack>
                </React.Fragment>
              )}

            {employeeTranslatedInfo?.expertTalks &&
              employeeTranslatedInfo?.expertTalks.length > 0 && (
                <React.Fragment>
                  <TextHeading text={"expertTalks"} />

                  <VStack alignItems={"flex-start"} spacing={4}>
                    {employeeTranslatedInfo?.expertTalks
                      //sort by year and month
                      .sort(
                        (a, b) =>
                          new Date(b.date).getTime() -
                          new Date(a.date).getTime()
                      )
                      .map((expertTalk) => (
                        <PublicationCard publication={expertTalk} />
                      ))}
                  </VStack>
                </React.Fragment>
              )}
          </Box>
        )}
      </MaxWidthContainer>
    </VStack>
  );
}

export function PublicationCard({ publication }: { publication: Publication }) {
  const pubDate = new Date(publication.date);

  return (
    <SimpleGrid
      templateColumns={{
        base: "90px auto",
      }}
      flexDirection="row"
      spacing={8}
      alignItems={"flex-start"}
      w="100%"
    >
      <VStack alignItems={"flex-start"} spacing={1}>
        <Text
          colorScheme="black"
          fontWeight={500}
          px={0}
          mt={"1px"}
          textTransform={"capitalize"}
          fontSize={"12px"}
        >
          {pubDate.toLocaleDateString(i18n.language, {
            month: "2-digit",
            year: "numeric",
          })}
        </Text>
        {publication.location && (
          <>
            <Divider borderColor={companyColors.orange[600]} w={"100%"} />
            <Text
              colorScheme="black"
              px={0}
              textTransform={"uppercase"}
              fontSize={"12px"}
              fontWeight={800}
            >
              {publication.location}
            </Text>
          </>
        )}
      </VStack>
      <Text>
        <Box
          dangerouslySetInnerHTML={{ __html: publication.title || "" }}
        ></Box>
      </Text>

      {/*       {publication.link ? (
        <Text isTruncated as="a" href={publication.link} target="_blank">
          <IconButton
            aria-label="Link"
            icon={<RxDoubleArrowRight />}
            size={"xs"}
          />
        </Text>
      ) : (
        <></>
      )} */}
    </SimpleGrid>
  );
}

export function TextHeading({
  text,
  mt,
}: {
  text: string;
  mt?: undefined | number;
}) {
  const { t } = useTranslation();

  return (
    <Text
      fontSize={18}
      fontWeight={500}
      mt={mt || 10}
      bg={companyColors.blue[200]}
      p={2}
      rounded={4}
      mb={2}
    >
      {t(text)}
    </Text>
  );
}

function GetEmployeeDetails(slug: string): Employee {
  return employeeList.filter((x) => x.slug == slug)[0];
}

function GetEmployeeTranslatedInfo(
  exmployee: Employee,
  languageCode?: websiteLanguages | undefined
): (EmployeeTranslatedInfo & TranslatedInfo) | undefined {
  if (exmployee.translations && exmployee.translations?.length > 0) {
    let langToFind = languageCode || fallbackLng();
    let translatedInfo = exmployee.translations.find(
      (item) => item.languageCode === langToFind
    );
    if (translatedInfo) {
      return translatedInfo.translation;
    } else {
      return exmployee.translations[0].translation;
    }
  } else {
    return undefined;
  }
}
