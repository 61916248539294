import {
  AspectRatio,
  Badge,
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  useBreakpointValue,
  useDisclosure,
  useStyleConfig,
  VStack,
} from "@chakra-ui/react";
import logoTyping from "../../media/Logo_NoMargin.svg";
import { Image } from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import { MaxWidthContainer } from "./maxWidthContainer";
import { useTranslation } from "react-i18next";
import { supportedLngs } from "../i18n";
import { companyColors } from "../../global/companyColors";
import { FaLinkedin } from "react-icons/fa";
import { Footer, SocialButton } from "./footer";

import { RxCross1, RxHamburgerMenu } from "react-icons/rx";
import React, { useEffect, useState } from "react";
import { addDomEvent } from "@chakra-ui/utils";
import { OpenPositionCount } from "../../pages/career/openPositionCount";

const navElements = [
  /*   "competences", */
  "services",
  "team",
  "csr",

  "career",
  "contact",
];

// insights -> (als dropdown)

// Veröffentlichungen (Publications)
// social media (=)
// events (=)
// Auszeichnungen (awards)

export function Header() {
  const hideWhenSmall = useBreakpointValue(
    { base: "none", md: "inherit" },
    { ssr: false }
  );
  const hideWhenBig = useBreakpointValue(
    { base: "inherit", md: "none" },
    { ssr: false }
  );
  const marginLogoContainer = useBreakpointValue(
    { base: 4, md: 10 },
    { ssr: false }
  );

  const logoMinWidth = useBreakpointValue(
    { base: "70vw", md: 220 },
    { ssr: false }
  );
  const { languageCode, section } = useParams();
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    if (
      window.location.pathname.substring(4).startsWith("social-media") ||
      window.location.pathname.substring(4).startsWith("events")
    ) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [window.location.pathname]);

  const stylesInactive = useStyleConfig("Box", { variant: "mainNav" });
  const stylesSelected = useStyleConfig("Box", { variant: "mainNavSelected" });

  return (
    <MaxWidthContainer my={marginLogoContainer}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Link to={"/" + languageCode}>
            <Image
              src={logoTyping}
              style={{
                maxWidth: 420,
                width: "50vw",
                minWidth: logoMinWidth,
              }}
            />
          </Link>
        </Box>
        <HStack spacing={4} display={hideWhenSmall}>
          {supportedLngs.map((lng) => LanguageButton(lng))}
        </HStack>
        <Box display={hideWhenBig}>
          <BurgerMenu />
        </Box>
      </Box>
      <Box display={hideWhenSmall}>
        {" "}
        <Box display="flex" justifyContent="space-between" mt={"35px"}>
          <HStack spacing={8}>
            {navElements.map((navElement) => (
              <NavigationButton
                key={navElement}
                navElement={navElement}
                activeSection={section}
                onClick={undefined}
              />
            ))}
          </HStack>

          {/*           <NavigationButton
            key={"insights"}
            navElement={"insights"}
            activeSection={section}
            onClick={undefined}
            navLabel={"Insights"}
          /> */}
        </Box>
      </Box>
    </MaxWidthContainer>
  );
}

function NavigationButton({
  navElement,
  navLabel,
  activeSection,
  onClick,
}: {
  navElement: string;
  navLabel?: string | undefined;
  activeSection: string | undefined;
  onClick?: (() => void) | undefined;
}): JSX.Element {
  const { t } = useTranslation();
  const [active, setActive] = useState<boolean>(false);
  const stylesInactive = useStyleConfig("Box", { variant: "mainNav" });
  const stylesSelected = useStyleConfig("Box", { variant: "mainNavSelected" });
  useEffect(() => {
    if (window.location.pathname.substring(4).startsWith(navElement)) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [window.location.pathname]);
  return (
    <HStack alignItems="flex-start" spacing={2}>
      <Box
        as={Link}
        __css={active ? stylesSelected : stylesInactive}
        to={navElement}
        onClick={() => onClick && onClick()}
        /*       style={{
        borderBottomWidth: 2,
      }}
      _hover={{ borderBottomColor: companyColors.orange[500] }} */
      >
        {t(navLabel || navElement)}
      </Box>
      {navElement == "career" && <OpenPositionCount />}
    </HStack>
  );
}

function LanguageButton(lng: {
  code: string;
  shortName: string;
  isFallback: boolean;
  isDefault: boolean;
}): JSX.Element {
  return (
    <Link
      key={lng.code}
      to={`/${lng.code}${window.location.pathname.substring(3)}`}
      style={{
        color: companyColors.blue[1000],
        textDecoration: "none",
      }}
    >
      {lng.shortName}
    </Link>
  );
}

export function SocialButtons() {
  return (
    <SocialButton label={"LinkedIn"} href={LinkedInLink}>
      <FaLinkedin size="24" />
    </SocialButton>
  );
}

export const LinkedInLink =
  "https://www.linkedin.com/company/mangal-legal-rechtsanwaltsgesellschaft-mbh";

function BurgerMenu() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const { languageCode, section } = useParams();
  const [height, setHeight] = useState(window.innerHeight);
  useEffect(
    () => addDomEvent(window, "resize", () => setHeight(window.innerHeight)),
    []
  );

  return (
    <Box alignContent="center" flexWrap="wrap" display="flex">
      <Button onClick={onOpen} variant="ghost">
        <RxHamburgerMenu size="24" />
      </Button>

      <Drawer isOpen={isOpen} placement="right" size="full" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent
          backgroundColor={companyColors.blue[400]}
          height={height}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            flexGrow={1}
          >
            <AspectRatio
              maxW="100%"
              ratio={420 / 78}
              bg={companyColors.blue[200]}
            >
              <MaxWidthContainer>
                <SimpleGrid
                  templateColumns={{ base: "1fr 1fr" }}
                  flexDirection="column"
                  spacing={8}
                  w={"100%"}
                >
                  <HStack spacing={4}>
                    {supportedLngs.map((lng) => LanguageButton(lng))}
                  </HStack>

                  <Box justifyContent="flex-end" display="flex">
                    <Button onClick={onClose} variant="ghost">
                      <RxCross1 size="24" />
                    </Button>
                  </Box>
                </SimpleGrid>
              </MaxWidthContainer>
            </AspectRatio>
            <MaxWidthContainer>
              <VStack spacing={8} mt={4}>
                <NavigationButton
                  key="start"
                  navElement={"/" + languageCode}
                  navLabel={"Start"}
                  activeSection={undefined}
                  onClick={onClose}
                />
                {navElements
                  .filter((x) => x != "contact")
                  .map((navElement) => (
                    <NavigationButton
                      key={navElement}
                      navElement={navElement}
                      activeSection={undefined}
                      onClick={onClose}
                    />
                  ))}
                {/*                 <NavigationButton
                  key={"insights"}
                  navElement={"insights"}
                  activeSection={section}
                  onClick={onClose}
                  navLabel={"Insights"}
                /> */}
              </VStack>
            </MaxWidthContainer>
            <Box bg={companyColors.blue[200]}>
              <Footer mobileMenuOnClick={onClose} />
            </Box>
          </Box>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}
