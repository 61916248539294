import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

export type websiteLanguages = "de" | "en" | "es";

export type supportedLngsFori18tn = {
  code: websiteLanguages;
  shortName: string;
  isFallback: boolean;
  isDefault: boolean;
};

export type TranslatedInfo = {
  introText: JSX.Element;
};

export type Translations<T> = {
  languageCode: websiteLanguages;
  translation: TranslatedInfo & T;
};

export function convertStringToWebsiteLang(
  languageCode: string | undefined
): websiteLanguages {
  if (languageCode == "de") {
    return "de";
  }
  if (languageCode == "en") {
    return "en";
  }
  return fallbackLng();
}

export const supportedLngs: supportedLngsFori18tn[] = [
  { code: "en", shortName: "EN", isFallback: true, isDefault: false },
  { code: "de", shortName: "DE", isFallback: false, isDefault: true },
  /*  { code: "es", shortName: "ES", isFallback: false, isDefault: true }, */
];

export function fallbackLng(): websiteLanguages {
  return (
    supportedLngs.filter((lng) => lng.isFallback)[0].code ||
    supportedLngs[0].code
  );
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(Backend)
  .init({
    supportedLngs: supportedLngs.map((lng) => lng.code),
    // lng: "en",
    ns: [
      "translation",
      "contentTeam",
      "contentServices",
      "contentImprint",
      "contentDataPrivacy",
    ],
    defaultNS: "translation",
    fallbackLng: fallbackLng(),
    detection: {
      order: ["path"],
      lookupFromPathIndex: 0,
      caches: ["cookie"],
    },
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    interpolation: { escapeValue: false }, // Disables escaping
  });

export default i18n;
