import { VStack, Button, Box } from "@chakra-ui/react";

import { t } from "i18next";
import { Trans } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { BannerWithHeading } from "../../generic/layout/bannerWithHeading";
import { HeadingMedium } from "../../generic/layout/headingMedium";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { TextHeading } from "../team/EmployeeDetails";

export function TraineeLawyer() {
  const { languageCode } = useParams();
  return (
    <VStack spacing={0}>
      <BannerWithHeading bgUrl="/media/bg3.jpg" headingText={t("career")} />

      <Box py={8}>
        <MaxWidthContainer>
          <HeadingMedium mb={0}>Referendare (m/w/d)</HeadingMedium>
          <p>&nbsp;</p>
          <p>
            Zur Verst&auml;rkung unseres Teams in Hamburg suchen wir ab sofort{" "}
            <strong>Referendare (m/w/d).</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            Wir bieten Ihnen eine spannende T&auml;tigkeit in einer
            aufstrebenden Kanzlei. Neben der markt- und leistungsgerechten
            Verg&uuml;tung bieten wir Ihnen die M&ouml;glichkeit des gemeinsamen
            Wachsens in einem jungen dynamischen Team.
          </p>
          <p>&nbsp;</p>
          <p>
            Unsere Struktur erlaubt es uns, Sie ab dem ersten Tag unter
            Ber&uuml;cksichtigung Ihrer F&auml;higkeiten und Interessen in die
            Mandatsarbeit voll einzubinden. Wir sind &uuml;berzeugt, dass
            Vielfalt Teams st&auml;rker, intelligenter und effektiver macht und
            dass hervorragende Leistung nur im Team erbracht werden kann. Wir
            sind stolz auf unsere offene, angenehme und respektvolle
            Unternehmenskultur und freuen uns &uuml;ber Ihre Bewerbung.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Aufgabenbereiche</strong>
          </p>
          <ul>
            <li>
              Sie arbeiten eng mit unseren Rechtsanw&auml;lt:innen zusammen und
              werden schrittweise eingearbeitet.
            </li>
            <li>
              Sie bearbeiten selbstst&auml;ndig Aufgaben innerhalb des gesamten
              Mandats und unterst&uuml;tzen uns bei der Erstellung von
              Gutachten, Aufs&auml;tzen, Schrifts&auml;tzen sowie
              Vertragsentw&uuml;rfen.
            </li>
            <li>
              Sie &uuml;bernehmen Recherchet&auml;tigkeiten und begleiten unsere
              Rechtsanw&auml;lt:innen zu Terminen.
            </li>
            <li>
              Sie arbeiten kreativ an der Gestaltung von Pr&auml;sentationen
              f&uuml;r Vortr&auml;ge mit.
            </li>
            <li>
              Sie unterst&uuml;tzen aktiv inhaltlich und organisatorisch
              zentrale Projekte der Kanzlei.
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>
            <strong>Qualifikationen</strong>
          </p>
          <ul>
            <li>
              Sie haben Ihre erste juristische Pr&uuml;fung mit guten
              Ergebnissen abgeschlossen.
            </li>
            <li>
              Idealerweise konnten Sie bereits erste berufliche Erfahrung in
              einer Rechtsanwaltskanzlei sammeln.
            </li>
            <li>
              Sie haben Lust sich selbstst&auml;ndig in verschiedene dynamische
              Aufgabenbereiche einzuarbeiten und kreativ an verschiedenen
              Projekten mitzuwirken.
            </li>
            <li>
              Sie zeichnen sich durch eine strukturierte Arbeitsweise aus und
              verf&uuml;gen &uuml;ber eine schnelle Auffassungsgabe sowie ein
              gro&szlig;es Verantwortungsbewusstsein.
            </li>
            <li>
              Sie verf&uuml;gen &uuml;ber einen guten, routinierten Umgang mit
              Microsoft Outlook, inkl. Word, Excel und PowerPoint.
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>
            Wir freuen uns darauf, Sie kennenzulernen. Richten Sie bitte Ihre{" "}
            <strong>Bewerbungen per E-Mail an </strong>
            Frau&nbsp;Rechtsanw&auml;ltin Dr. Wajma Mangal (
            <a href="mailto:info@mangal.legal">info@mangal.legal</a>).
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Sie haben noch Fragen?</strong>
          </p>
          <p>Dann melden Sie sich gern unter +49 40 357 00 97 0.</p>
        </MaxWidthContainer>
      </Box>
    </VStack>
  );
}
